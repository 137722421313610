let rotationNb = 0;

function delay(ms) { return new Promise(res => setTimeout(res, ms)); }

async function reversePage() {
	const style = document.querySelector("body").style;
	const button = document.getElementById("funbutton");
	switch (rotationNb) {
		case 0:
			style.rotate = "x 180deg";
			button.innerText = "Mode toupie ?";
			break;
		case 1:
			button.innerText = "Yahouuuuuu";
			rotationNb = -1;
			for (let i = 0; i < 150; i++) {
				style.rotate = `${Math.floor(Math.random() * 360)}deg`;
				await delay(50);
			}
			style.rotate = "0deg";
			button.innerText = "Mode miroir ?";
	}
	rotationNb++;
}

async function init() {

	const text = document.getElementById("mavisuptime");
	const mavis = await fetch("https://remi.demaistre.fr/Mavis/up.php").then(x => x.json());
	if (mavis.up) {
		text.style.color = "#0f0";
		text.innerText = "◉ Running";
	} else {
		text.style.color = "#f00";
		text.innerText = "◉ Not running";
	}
}
